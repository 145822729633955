import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import { MenuItem, FormControl, Select } from "@mui/material";
import { addDefaultOpt } from "../../../../../../utils/helpers";

const fontSx = {
  fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
};

const RegionSelector = ({
  disabled,
  selectedRegion,
  initialRegion,
  regions,
  onRegionChange,
}) => {
  const [regionOptions, setRegionOptions] = useState([]);

  useEffect(() => {
    setRegionOptions(
      addDefaultOpt(
        Object.entries(regions)?.map(([option, optionName]) => {
          return {
            value: option,
            label: option === initialRegion ? `${optionName} (*)` : optionName,
          };
        })
      )
    );
  }, [initialRegion, regions]);

  return (
    <>
      <Grid item xs={4}>
        <Typography sx={fontSx} className={"text-gray-600"}>
          Select Region:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <div>
          <FormControl fullWidth disabled={disabled}>
            <Select
              labelId="region-select-label"
              id="region-select"
              value={selectedRegion}
              onChange={(e) => onRegionChange(e.target.value)}
              style={{ height: 35 }}
            >
              {regionOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Grid>
    </>
  );
};

export default RegionSelector;

import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Settings from "./Settings";
import { timeZoneOptions } from "../../../../../utils/const";
import { updateUserTimeZoneSettings, updateUserAddressSearchRadiusSettings, updateUserMapCenterSettings, setMapCenter, updateDeviceVectorType, } from '../../../../../actions'
import { useWebServer } from "../../../../../providers";
import AutoFillSettingModal from "../SpecimenDetails/AutoFillSettingModal";
import { setMapCenterPlacementMode } from "../../../../../actions/map";
import AlgorithmSettingModal from './AlgorithmSettingModal'

const DEFAULT_CHANGED = { mapCenter: false, addressSearchRadius: false, timeZone: false }

const DeviceSetup = ({
    user,
    updateUserTimeZoneSettings,
    updateUserAddressSearchRadiusSettings,
    updateUserMapCenterSettings,
    mapCenterPlacementMode,
    setMapCenterPlacementMode,
    updateDeviceVectorType,
    setMapCenter,
    initialVectorType,
    device,
}) => {
    const [autofillModalOpen, setAutofillModalOpen] = useState(false)
    const [algorithmSettingModalOpen, setAlgorithmSettingModalOpen] = useState(false)
    const { email, organization, name, mapCenter, timeZone, addressSearchRadius } = user
    const addDefaultTimeZoneOptions = [
        {
            value: "AUTO",
            abbr: "",
            offset: 0,
            isdst: false,
            text: "Auto Detect",
            utc: [""],
        },
        ...timeZoneOptions
    ]
    const timeZoneValue = addDefaultTimeZoneOptions.find((element) => element.value === timeZone)
    const { sendRequest } = useWebServer()
    const [changed, setChanged] = useState(DEFAULT_CHANGED)
    const [initialValue, setInitialValue] = useState(null)
    const [init, setInit] = useState(false)
    const valueRef = useRef();

    useEffect(() => {
        let { mapCenter, timeZone, addressSearchRadius, fetched } = user
        if (fetched && !init) {
            setInitialValue({ mapCenter, timeZone, addressSearchRadius })
            setInit(true)
        }
    }, [user, init])

    useEffect(() => {
        valueRef.current = { changed, initialValue };
    }, [changed, initialValue]);

    const cleanUp = () => {
        let { changed, initialValue } = valueRef.current
        if (initialValue && (changed?.timeZone || changed?.addressSearchRadius || changed?.mapCenter)) {
            updateUserTimeZoneSettings(initialValue.timeZone)
            updateUserAddressSearchRadiusSettings(initialValue.addressSearchRadius)
            updateUserMapCenterSettings(initialValue.mapCenter)
        }
    }

    useEffect(() => {
        return () => {
            cleanUp()
        }
        // eslint-disable-next-line
    }, [])

    const saveUserSetting = async (timeZone, mapCenter, addressSearchRadius) => {
        if (changed?.mapCenter) {
            await sendRequest("patch")({
                url: `/user/user-mapCenter`,
                devPort: 5004,
                data: { mapCenter: mapCenter },
            });
            let arr = mapCenter.split(',')
            setMapCenter([parseFloat(arr[0]), parseFloat(arr[1])]);
        }

        if (parseInt(addressSearchRadius) >= 0 && changed?.addressSearchRadius) {
            await sendRequest("patch")({
                url: `/user/user-addressSearchRadius`,
                devPort: 5004,
                data: { addressSearchRadius: addressSearchRadius },
            });
        }
        if (changed?.timeZone)
            await sendRequest("patch")({
                url: `/user/user-timezone`,
                devPort: 5004,
                data: { timeZone: timeZone },
            });
        setChanged(DEFAULT_CHANGED)
    }

    const onUpdateDeviceVectorType = (deviceUuid, newVectorType) => {
        console.log(
            `updateDeviceVectorType in Settings ${deviceUuid}, ${newVectorType}`
        );
        updateDeviceVectorType(deviceUuid, newVectorType);
    };

    return (
        <>
            <AutoFillSettingModal
                autofillModalOpen={autofillModalOpen}
                setAutofillModalOpen={setAutofillModalOpen}
            />
            <AlgorithmSettingModal
                algorithmSettingModalOpen={algorithmSettingModalOpen}
                setAlgorithmSettingModalOpen={setAlgorithmSettingModalOpen}
            />
            <Settings
                email={email}
                user={user}
                name={name}
                saveUserSetting={saveUserSetting}
                addressSearchRadius={addressSearchRadius}
                timeZone={timeZoneValue}
                organization={organization}
                mapCenter={mapCenter}
                setAutofillModalOpen={setAutofillModalOpen}
                setAlgorithmSettingModalOpen={setAlgorithmSettingModalOpen}
                algorithmSettingModalOpen={algorithmSettingModalOpen}
                timeZoneOptions={addDefaultTimeZoneOptions}
                updateUserTimeZoneSettings={(value) => {
                    updateUserTimeZoneSettings(value)
                    setChanged({ ...changed, timeZone: true, })
                }}
                updateUserAddressSearchRadiusSettings={(value) => {
                    updateUserAddressSearchRadiusSettings(value)
                    setChanged({ ...changed, addressSearchRadius: true, })
                }}
                updateUserMapCenterSettings={(value) => {
                    updateUserMapCenterSettings(value)
                    setChanged({ ...changed, mapCenter: true, })
                }}
                mapCenterPlacementMode={mapCenterPlacementMode}
                setMapCenterPlacementMode={setMapCenterPlacementMode}
                onUpdateDeviceVectorType={onUpdateDeviceVectorType}
                initialVectorType={initialVectorType}
            ></Settings>
        </>
    )

};

export const mapStateToProps = (state) => {
    const user = state.user;
    const mapCenterPlacementMode = state.map.mapCenterPlacementMode
    let selectedDevice = state.devices?.selectedDevice || "";
    let device = state.devices?.[selectedDevice];
    const initialVectorType = device?.vectorType;


    return {
        user,
        mapCenterPlacementMode,
        initialVectorType,
        device,
    };
};

export default connect(mapStateToProps,
    {
        updateUserTimeZoneSettings,
        updateUserAddressSearchRadiusSettings,
        updateUserMapCenterSettings,
        setMapCenterPlacementMode,
        setMapCenter,
        updateDeviceVectorType,
    })(DeviceSetup);

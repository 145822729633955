import {
  FETCH_ALG_VECTORTYPES,
  FETCH_ALG_VECTORTYPES_BY_VERSION,
} from "./types";

import { displayErrorNotification } from "./notifications";

export const fetchAlgVectorTypes = (sendRequest) => async (dispatch) => {
  let response;
  try {
    response = await sendRequest("get")({
      url: "/algVersions/vectorTypes",
      devPort: 5005,
    });
  } catch (err) {
    if (
      err?.response.status === 500 &&
      err?.response?.data?.message === "VectorTypes could not be found"
    ) {
      response = { data: {} };
    }
  }
  dispatch({ type: FETCH_ALG_VECTORTYPES, payload: response.data });
};

export const fetchAlgVectorTypesGivenAlgVersion =
  (sendRequest, algVersion) => async (dispatch) => {
    if (
      (typeof algVersion === "string" || algVersion instanceof String) &&
      algVersion.length > 0
    ) {
      const res = await sendRequest("get")({
        url: `/algVersions/vectorTypes/version/${algVersion}`,
        devPort: 5005,
      }).catch((err) => {
        dispatch(displayErrorNotification("Failed to fetch vector types"));
      });
      if (res?.status === 200) {
        dispatch({
          type: FETCH_ALG_VECTORTYPES_BY_VERSION,
          payload: { version: algVersion, ...res.data },
        });
      }
    }
  };

import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  TextField,
  Tooltip,
  Button,
  createTheme,
  ThemeProvider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ResponsiveIconButton from "../../../../Forms/ResponsiveIconButton";
import AutoCompleteField from "../../../../Forms/AutoCompleteField";
import MapIcon from "@mui/icons-material/Map";
import MapCenterInput from "./MapCenterInput";
import ResponsiveButton from "../../../../Forms/ResponsiveButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import DeviceInfoPanel from "./DeviceInfoPanel";

const fontSx = {
  fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
};

const buttonStyles = {
  autofillButton: {
    color: "status.info",
    borderColor: "status.info",
  },
  infoButton: {
    color: "status.info",
  },
};

const numberInputSx = {
  "& .MuiOutlinedInput-input": {
    fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
    py: ["0.25rem", "0.25rem", "0.25rem", "0.25rem", "0.5rem", "0.5rem"],
  },
};

const theme = createTheme({
  components: {
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          // '&.Mui-collapsed': {
          //     minHeight: '55px', // Adjust minHeight when collapsed
          // },
          "&.Mui-expanded": {
            minHeight: "55px", // Adjust minHeight when expanded
          },
        },
      },
    },
  },
});

const toTitleCase = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

const formatStateMachine = (str) => toTitleCase(str).replace("_", " ");

const Settings = ({
  email,
  name,
  setAutofillModalOpen,
  setAlgorithmSettingModalOpen,
  algorithmSettingModalOpen,
  organization,
  mapCenter,
  timeZoneOptions,
  timeZone,
  updateUserTimeZoneSettings,
  addressSearchRadius,
  updateUserAddressSearchRadiusSettings,
  updateUserMapCenterSettings,
  mapCenterPlacementMode,
  setMapCenterPlacementMode,
  saveUserSetting,
  initialVectorType,
}) => {
  const [value, setValue] = useState({
    value: "AUTO",
    abbr: "",
    offset: 0,
    isdst: false,
    text: "Auto Detect",
    utc: [""],
  });
  const [inputValue, setInputValue] = useState("");

  const handleSave = () => {
    saveUserSetting(timeZone.value, mapCenter, addressSearchRadius);
  };

  const [userInfoOpen, setUserInfoOpen] = useState(true);
  const [deviceInfoOpen, setDeviceInfoOpen] = useState(true);
  const [orgInfoOpen, setOrgInfoOpen] = useState(true);

  const [vectorSpecies, setVectorSpecies] = useState("");

  useEffect(() => {
    setVectorSpecies(initialVectorType?.startsWith("m") ? "mosquito" : "tick");
  }, [initialVectorType, setVectorSpecies]);

  const [isEditing, setIsEditing] = useState(false);

  const [deviceUuidValue, setDeviceUuidValue] = useState("");
  const [selectedRegionValue, setSelectedRegionValue] = useState("");
  const [vectorTypeValue, setVectorTypeValue] = useState("");

  const deviceUuid = (uuid) => {
    setDeviceUuidValue(uuid);
  };

  const vectorSaved = (vectorselected) => {
    setVectorTypeValue(vectorselected);
  };

  const selectedRegion = (regionType) => {
    setSelectedRegionValue(regionType);
  };

  // disabling on device change
  useEffect(() => {
    if (deviceUuidValue) {
      setIsEditing(false);
    }
  }, [deviceUuidValue]);

  useEffect(() => {
    setValue(timeZone);
  }, [timeZone]);
  return (
    <Box
      sx={{
        width: "100%",
        px: "1rem",
        pt: "1rem",
      }}
    >
      <Grid container spacing={3}>
        {/* User Info Accordion */}
        <ThemeProvider theme={theme}>
          <Accordion
            expanded={userInfoOpen}
            onChange={() => setUserInfoOpen(!userInfoOpen)}
          >
            <AccordionSummary
              expandIcon={
                userInfoOpen ? (
                  <ArrowDropUpIcon className="text-white" />
                ) : (
                  <ArrowDropDownIcon className="text-white" />
                )
              }
              sx={{
                backgroundColor: "#3f51b5",
                border: "2px solid white",
                minHeight: 50,
                height: 50,
              }}
              className={"text-white"}
            >
              <Typography>User Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography sx={fontSx} className={"text-gray-600"}>
                    Name:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={fontSx}>{name}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography sx={fontSx} className={"text-gray-600"}>
                    Email:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={fontSx}>{email}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography sx={fontSx} className={"text-gray-600"}>
                    Time Zone:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <AutoCompleteField
                    noLabel={true}
                    value={value}
                    onChange={(event, newValue) => {
                      updateUserTimeZoneSettings(newValue?.value);
                      setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    getOptionLabel={(option) => option.abbr + " " + option.text}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value?.value
                    }
                    options={timeZoneOptions}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={fontSx} className={"text-gray-600"}>
                    Map Center:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ flexBasis: "80%" }}>
                      <MapCenterInput
                        mapCenter={mapCenter}
                        updateUserMapCenterSettings={
                          updateUserMapCenterSettings
                        }
                      />
                    </Box>

                    <Box sx={{ flexBasis: "20%" }}>
                      <Tooltip
                        title={
                          <Typography variant="subtitle1">
                            Click to enable map center placement mode on map
                          </Typography>
                        }
                      >
                        <Button onClick={() => setMapCenterPlacementMode(true)}>
                          <MapIcon
                            color={
                              mapCenterPlacementMode ? "disabled" : "primary"
                            }
                          />
                        </Button>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Typography sx={fontSx} className={"text-gray-600"}>
                    Address Search Radius:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    value={addressSearchRadius}
                    type="number"
                    inputProps={{ step: 10 }}
                    sx={numberInputSx}
                    onChange={(event) => {
                      const value = parseInt(event.target.value, 0);
                      updateUserAddressSearchRadiusSettings(value);
                    }}
                    onBlur={(event) => {
                      if (
                        event.target.value === "" ||
                        parseInt(event.target.value) <= 0
                      ) {
                        updateUserAddressSearchRadiusSettings(100); // Default to 100 if invalid value
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={fontSx} className={"text-gray-600"}>
                    Specimen Autofill:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ResponsiveIconButton
                      onClick={() => setAutofillModalOpen(true)}
                    >
                      <SettingsIcon sx={buttonStyles.infoButton} />
                    </ResponsiveIconButton>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    mt: "10px",
                  }}
                >
                  <ResponsiveButton
                    variant="contained"
                    style={{
                      color: "#2196F3",
                      backgroundColor: "white",
                      borderColor: "rgba(63, 81, 181, 0.5)",
                      border: "1px solid",
                      boxShadow: "none",
                    }}
                    onClick={handleSave}
                  >
                    SAVE
                  </ResponsiveButton>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </ThemeProvider>
        {/* Device Info Accordion */}
        <ThemeProvider theme={theme}>
          <Accordion
            expanded={deviceInfoOpen}
            onChange={() => setDeviceInfoOpen(!deviceInfoOpen)}
          >
            <AccordionSummary
              expandIcon={
                deviceInfoOpen ? (
                  <ArrowDropUpIcon className="text-white" />
                ) : (
                  <ArrowDropDownIcon className="text-white" />
                )
              }
              sx={{
                backgroundColor: "#3f51b5",
                border: "2px solid white",
                minHeight: 50,
                height: 50,
              }}
              className={"text-white"}
            >
              <Typography>Device Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DeviceInfoPanel />
            </AccordionDetails>
          </Accordion>
        </ThemeProvider>

        {/* Organization Info Accordion */}
        <ThemeProvider theme={theme}>
          <Accordion
            expanded={orgInfoOpen}
            onChange={() => setOrgInfoOpen(!orgInfoOpen)}
          >
            <AccordionSummary
              expandIcon={
                orgInfoOpen ? (
                  <ArrowDropUpIcon className="text-white" />
                ) : (
                  <ArrowDropDownIcon className="text-white" />
                )
              }
              sx={{
                backgroundColor: "#3f51b5",
                border: "2px solid white",
                minHeight: 50,
                height: 50,
              }}
              className={"text-white"}
            >
              <Typography>Organization Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography sx={fontSx} className={"text-gray-600"}>
                    Organization:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography sx={fontSx}>{organization}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography sx={fontSx} className={"text-gray-600"}>
                    Algorithm Setting:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ResponsiveIconButton
                      onClick={() => setAlgorithmSettingModalOpen(true)}
                    >
                      <SettingsIcon sx={buttonStyles.infoButton} />
                    </ResponsiveIconButton>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </ThemeProvider>
      </Grid>
    </Box>
  );
};

export default Settings;

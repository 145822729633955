import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ResponsiveButton from "../../../../../Forms/ResponsiveButton";
import DeviceSelector from "./DeviceSelector";
import RegionSelector from "./RegionSelector";
import VectorTypeSelector from "./VectorTypeSelector";
import { getVectorTypeGivenRegion } from "../../../../../../utils/helpers";

const DeviceInfoPanel = ({
  devices,
  onUpdateDeviceVectorType,
  deviceUuid,
  onUpdateDeviceUuid,

  possibleVectorTypes,
  initialRegion,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedVectorTypeValue, setSelectedVectorTypeValue] = useState("");
  const [selectedRegionValue, setSelectedRegionValue] = useState("");
  const [regions, setRegions] = useState({});

  // save is disabled when not dirty
  const saveDisabled =
    !isEditing ||
    (selectedRegionValue || "").length === 0 ||
    selectedRegionValue === initialRegion;

  const handleSaveButtonPressed = () => {
    if (selectedRegionValue.length > 0)
      onUpdateDeviceVectorType(selectedRegionValue);
    setIsEditing(false);
  };

  const handleToggleEdit = () => {
    if (isEditing) {
      // handle CANCEL
      setIsEditing(false);
      const initialVectorType = getVectorTypeGivenRegion(initialRegion);
      setSelectedRegionValue(initialRegion);
      setSelectedVectorTypeValue(initialVectorType);
    } else {
      // handle EDIT
      setIsEditing(true);
    }
  };

  // if selectedVectorType changes, update regions
  useEffect(() => {
    setRegions(possibleVectorTypes?.[selectedVectorTypeValue] || {});
  }, [possibleVectorTypes, selectedVectorTypeValue, setRegions]);

  // if initial region changes, reset selectors
  useEffect(() => {
    const initialVectorType = getVectorTypeGivenRegion(initialRegion);
    setSelectedRegionValue(initialRegion);
    setSelectedVectorTypeValue(initialVectorType);
  }, [initialRegion, setSelectedRegionValue, setSelectedVectorTypeValue]);

  return (
    <Grid container spacing={3}>
      <DeviceSelector
        devices={devices}
        disabled={!isEditing}
        deviceUuid={deviceUuid}
        onDeviceChange={(s) => onUpdateDeviceUuid(s)}
      />

      <VectorTypeSelector
        disabled={!isEditing}
        selectedVectorType={selectedVectorTypeValue}
        onVectorTypeChange={setSelectedVectorTypeValue}
        possibleVectorTypes={possibleVectorTypes}
        initialRegion={initialRegion}
      />

      <RegionSelector
        disabled={!isEditing}
        selectedRegion={selectedRegionValue}
        initialRegion={initialRegion}
        regions={regions}
        onRegionChange={setSelectedRegionValue}
      />

      <Grid
        item
        xs={6}
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
          display: "flex",
          mt: "10px",
        }}
      >
        <ResponsiveButton
          variant="contained"
          style={{
            color: "#2196F3",
            backgroundColor: "white",
            borderColor: "rgba(63, 81, 181, 0.5)",
            border: "1px solid",
            boxShadow: "none",
          }}
          onClick={() => handleToggleEdit()}
        >
          {isEditing ? "CANCEL" : "EDIT"}
        </ResponsiveButton>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
          mt: "10px",
        }}
      >
        <ResponsiveButton
          variant="contained"
          style={{
            color: saveDisabled ? "gray" : "#2196F3",
            backgroundColor: "white",
            borderColor: saveDisabled ? "gray" : "rgba(63, 81, 181, 0.5)",
            border: "1px solid",
            boxShadow: "none",
            cursor: saveDisabled ? "not-allowed" : "pointer",
          }}
          disabled={saveDisabled}
          onClick={handleSaveButtonPressed}
        >
          SAVE
        </ResponsiveButton>
      </Grid>
    </Grid>
  );
};

export default DeviceInfoPanel;

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  fetchAlgVectorTypesGivenAlgVersion,
  updateDeviceVectorType,
} from "../../../../../../actions";
import { useWebServer } from "../../../../../../providers";
import { findLatestVersion } from "../../../../../../utils/helpers";
import DeviceInfoPanel from "./DeviceInfoPanel";

const Index = ({
  devices,
  selectedDevice,
  initialRegions,
  latestAlgVersion,
  possibleVectorTypes,

  fetchAlgVectorTypesGivenAlgVersion,
  updateDeviceVectorType,
}) => {
  const { sendRequest } = useWebServer();
  const [deviceUuid, setDeviceUuid] = useState(selectedDevice || "");
  const [initialRegion, setInitialRegion] = useState("");

  useEffect(() => {
    if (selectedDevice && selectedDevice !== "ALLTRAY") {
      setDeviceUuid(selectedDevice || "");
    }
  }, [selectedDevice, setDeviceUuid]);

  useEffect(() => {
    fetchAlgVectorTypesGivenAlgVersion(sendRequest, latestAlgVersion);
  }, [latestAlgVersion, sendRequest, fetchAlgVectorTypesGivenAlgVersion]);

  useEffect(() => {
    setInitialRegion(initialRegions?.[deviceUuid] || "");
  }, [initialRegions, deviceUuid, setInitialRegion]);

  const onUpdateDeviceUuid = (newDeviceUuid) => {
    setDeviceUuid(newDeviceUuid);
  };

  const onUpdateDeviceVectorType = (newVectorType) => {
    updateDeviceVectorType(deviceUuid, newVectorType);
  };

  return (
    <div>
      <DeviceInfoPanel
        devices={devices}
        onUpdateDeviceVectorType={onUpdateDeviceVectorType}
        deviceUuid={deviceUuid}
        onUpdateDeviceUuid={onUpdateDeviceUuid}
        possibleVectorTypes={possibleVectorTypes}
        initialRegion={initialRegion}
      />
    </div>
  );
};

export const mapStateToProps = (state) => {
  const { selectedDevice, ...devices } = state.devices;
  const initialRegions = Object.entries(devices || {}).reduce(
    (o, [deviceUuid, device]) => ({
      ...o,
      [deviceUuid]: device?.vectorType || "",
    }),
    {}
  );

  const algVersions = Object.values(devices).reduce((pre, cur) => {
    if (cur?.algVersion) {
      return [...pre, cur?.algVersion];
    }
    return pre;
  }, []);
  const latestAlgVersion = findLatestVersion(algVersions);

  const devicesObj = _.pickBy(
    devices,
    (value, _) => value?.env === process.env.REACT_APP_ENV.toUpperCase()
  );

  return {
    devices: devicesObj,
    selectedDevice,
    initialRegions,
    latestAlgVersion,
    possibleVectorTypes: state?.algVersion?.[latestAlgVersion] || {
      mosquito: {},
      tick: {},
    },
  };
};

export default connect(mapStateToProps, {
  fetchAlgVectorTypesGivenAlgVersion,
  updateDeviceVectorType,
})(Index);

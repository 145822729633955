import React, { useEffect, useState } from "react";
import { Typography, Box, Grid } from "@mui/material";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { addDefaultOpt } from "../../../../../../utils/helpers";

const fontSx = {
  fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
};

const DeviceSelector = ({ devices, disabled, deviceUuid, onDeviceChange }) => {
  const [deviceOptions, setDeviceOptions] = useState([]);

  useEffect(() => {
    setDeviceOptions(
      addDefaultOpt(
        Object.entries(devices)?.map(([value, info]) => {
          return {
            value: value,
            label: info?.displayName || value,
          };
        })
      )
    );
  }, [devices]);

  return (
    <>
      <Grid item xs={4}>
        <Typography sx={fontSx} className={"text-gray-600"}>
          Select Device:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <div>
          <FormControl fullWidth disabled={disabled}>
            <Select
              labelId="device-select-label"
              id="device-select"
              value={deviceUuid}
              onChange={(e) => onDeviceChange(e.target.value)}
              style={{ height: 35 }}
            >
              {deviceOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Grid>
    </>
  );
};

export default DeviceSelector;

import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {
  addDefaultOpt,
  getVectorTypeGivenRegion,
} from "../../../../../../utils/helpers";

const fontSx = {
  fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
};

const VectorTypeSelector = ({
  disabled,
  possibleVectorTypes,
  onVectorTypeChange,
  selectedVectorType,
  initialRegion,
}) => {
  const [vectorTypeOptions, setVectorTypeOptions] = useState([]);

  useEffect(() => {
    const initialVectorType = getVectorTypeGivenRegion(initialRegion);
    setVectorTypeOptions(
      addDefaultOpt(
        Object.keys(possibleVectorTypes || {})?.map((option) => {
          return {
            value: option,
            label: option === initialVectorType ? `${option} (*)` : option,
          };
        })
      )
    );
    onVectorTypeChange(initialVectorType);
  }, [
    initialRegion,
    possibleVectorTypes,
    setVectorTypeOptions,
    onVectorTypeChange,
  ]);

  return (
    <>
      <Grid item xs={4}>
        <Typography sx={fontSx} className={"text-gray-600"}>
          Select Vector Type:
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <FormControl fullWidth disabled={disabled}>
          <Select
            value={selectedVectorType || ""}
            onChange={(e) => onVectorTypeChange(e.target.value)}
            style={{ height: 35 }}
          >
            {vectorTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export default VectorTypeSelector;

import {
  FETCH_ALG_VECTORTYPES,
  FETCH_ALG_VECTORTYPES_BY_VERSION,
} from "../actions/types";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ALG_VECTORTYPES:
      return {
        ...state,
        vectorTypes: action?.payload?.vectorTypes,
      };
    case FETCH_ALG_VECTORTYPES_BY_VERSION:
      return {
        ...state,
        [action?.payload?.version]: action?.payload?.vectorTypes,
      };
    default:
      return state;
  }
};

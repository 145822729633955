import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

const fontSx = {
  fontSize: ["0.75rem", "0.75rem", "0.75rem", "0.75rem", "1rem", "1rem"],
};

const ChangeRegionDialog = ({
  actionName,
  device,
  active,
  vectorTypesMapping,
  setActive,
  onConfirm,
  cancelMessage = "Cancel",
}) => {
  const [options, setOptions] = useState([]);
  const [regions, setRegions] = useState({});

  useEffect(() => {
    let vType = device?.vectorType || "mosquito";
    if (vType.startsWith("moz")) {
      vType = "mosquito";
    } else if (vType.startsWith("tick")) {
      vType = "tick";
    }
    setRegions(vectorTypesMapping?.[vType] || {});
  }, [vectorTypesMapping, device]);

  useEffect(() => {
    setOptions(
      Object.entries(regions)?.map(([option, optionName]) => {
        return {
          value: option,
          label:
            option === (device?.vectorType || "mosquito")
              ? `${optionName} (*)`
              : optionName,
        };
      })
    );
    setMoveVal(device?.vectorType);
  }, [device, regions]);

  const [moveVal, setMoveVal] = useState(device?.vectorType || "mosquito");

  const handleClose = () => {
    setMoveVal("");
    setActive(null);
  };

  return !!active ? (
    <Dialog
      open={!!active}
      onClose={handleClose}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{`Change ${device?.displayName} (${device?.uuid})`}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <Typography sx={fontSx} className={"text-gray-600"}>
              Which region should this device use?
            </Typography>
            <TextField
              id="outlined-select-org"
              select
              label="New Region"
              value={moveVal}
              onChange={(event) => {
                setMoveVal(event.target.value);
              }}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          color="secondary"
          id={"dialog-cancel"}
        >
          {cancelMessage}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleClose();
            onConfirm(moveVal);
          }}
          id={"dialog-confirm"}
        >
          {actionName}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default ChangeRegionDialog;

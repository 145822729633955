import React from "react";
import { connect } from "react-redux";
import { updateUserAlgConfidenceSettings, updateAlgConfidenceSettings } from "../../../../../../actions";
import {
    Backdrop,
    Modal,
} from "@mui/material";
import { findLatestVersion } from "../../../../../../utils/helpers";
// import { useLocation } from "react-router-dom";
import AlgorithmSettingLayout from './AlgorithmSettingLayout'

const AlgorithmSettingModal = ({
    autofillSettings,
    algorithmSettingModalOpen,
    setAlgorithmSettingModalOpen,
    updateUserAutofillSettings,
    device,
    vectorType,
    algVersion,
    updateUserAlgConfidenceSettings,
    updateAlgConfidenceSettings,
    latestAlgVersion,
    uniqueVectorTypes,
    devicesVectorTypes,
    algorithmConfidence
}) => {
    const onClose = () => setAlgorithmSettingModalOpen(false);

    return (
        <div>
            <Modal
                open={algorithmSettingModalOpen}
                onClose={onClose}
                BackdropComponent={Backdrop}
            >
                <AlgorithmSettingLayout
                    updateUserAutofillSettings={updateUserAutofillSettings}
                    autofillSettings={autofillSettings}
                    onClose={onClose}
                    device={device}
                    vectorType={vectorType}
                    algVersion={algVersion}
                    latestAlgVersion={latestAlgVersion}
                    uniqueVectorTypes={uniqueVectorTypes}
                    devicesVectorTypes={devicesVectorTypes}
                    algorithmConfidence={algorithmConfidence}
                    updateAlgConfidenceSettings={updateAlgConfidenceSettings}
                />
            </Modal>
        </div>
    );
};

export const mapStateToProps = (state) => {
    const { autofillSettings } = state.user;
    const algorithmConfidence = state?.organization?.algorithmConfidence

    let selectedDevice = state.devices?.selectedDevice || "";
    let device = state.devices?.[selectedDevice];

    const algVersions = Object.values(state.devices).reduce((pre, cur) => {
        if (cur?.algVersion) {
            return [...pre, cur?.algVersion];
        }
        return pre;
    }, []);
    const vectorTypes = Object.values(state.devices).reduce((pre, cur) => {
        if (cur?.vectorType) {
            return [...pre, cur?.vectorType];
        }
        return pre;
    }, []);

    let devicesVectorTypes = [];
    Object.values(state.devices).forEach((d) => {
        const { displayName, vectorType, algVersion, env } = d;
        if (env === process.env.REACT_APP_ENV.toUpperCase()) {
            devicesVectorTypes.push({ displayName, vectorType, algVersion });
        }
    });

    const uniqueVectorTypes = [...new Set(vectorTypes)];
    console.log(uniqueVectorTypes[1])

    return {
        autofillSettings,
        vectorType: uniqueVectorTypes[1],
        algVersion: device?.algVersion || "v2.1.1",
        uniqueVectorTypes,
        latestAlgVersion: findLatestVersion(algVersions),
        devicesVectorTypes,
        algorithmConfidence,
    };
};

export default connect(mapStateToProps, { updateUserAlgConfidenceSettings, updateAlgConfidenceSettings })(
    AlgorithmSettingModal
);
